import { SERVER_URL } from "../service/config";
export const getEvents = async (token) => {
    try {
        const response = await fetch(`${SERVER_URL}event`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.ok) {
            const eventsData = await response.json();
            return eventsData;
          } else {
            console.error("User doesn't exist");
          }
    } catch (err) {
        console.error(err);
    }
}

export const getOneEvent = async (id, token) => {
  try {
      const response = await fetch(`${SERVER_URL}event/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const eventsData = await response.json();
          return eventsData;
        } else {
          console.error("User doesn't exist");
        }
  } catch (err) {
      console.error(err);
  }
}

export const addNewEvent = async(data, token) => {
    try {
        const response = await fetch(`${SERVER_URL}event`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          const { message, addedEvent } = await response.json();
  
          return addedEvent;
        }
      } catch (err) {
        console.error(err);
      }
}