import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    openEventModal: false,
    selectedEventId: null,
}

export const eventSlice = createSlice({
        name: "event",
        initialState,
        reducers: {
            setOpenEventModal: (state, action) => ({
                ...state,
                openEventModal: action.payload,
              }),
            setSelectedEventId: (state, action) => ({
                ...state,
                selectedEventId: action.payload.selectedId,
              }),
        }
})

export const { 
   setOpenEventModal,
   setSelectedEventId,
} = eventSlice.actions;

export default eventSlice.reducer