import { Box, Button, Container, IconButton, Modal, Rating, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenEventModal } from '../../state/event';
import style from './style.module.scss';
import clubDefaultLogo from '../../assets/images/club1.png';
import person from '../../assets/images/person.png';
import UserAvatar from '../CustomStyledComponents/UserAvatar';
import { getOneEvent } from '../../api/event';
import { monthNames } from '../../common/constants';


const EventModal = () => {
    const dispatch = useDispatch();
    const { openEventModal, selectedEventId} = useSelector((state) => state.event);
    const token = useSelector((state) => state.common.token);
    const [event, setEvent] = useState({});
    useEffect(() => {
        const getEvent = async() => {
          const event = await getOneEvent(selectedEventId, token);
          setEvent(event);
        }
        getEvent();
      }, []);
    const handleClose = () => {
        dispatch(setOpenEventModal(false));
      }
    return (
        <Modal
            open={openEventModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={style.customModal}>
                <Box className={style.inner} >
                    <Box className={style.topHeader}>
                        <img
                            src={event.user?.profilePhotoUrl[0]?.url ? event.user?.profilePhotoUrl[0].url : clubDefaultLogo}
                            alt="Logo"
                            style={{
                                width: '100px',
                                marginBottom: '16px'
                            }}
                        />
                        <Typography variant="h1" className={style.title} component="h1">{event.user?.username}</Typography>
                        <Typography variant="h2" className={style.title} component="h2">Event Name : <span>{event.name}</span></Typography>
                    </Box>
                    <Box className={style.eventDetails}>
                        <Typography variant="h4" className={style.title} component="h4">Event Theme</Typography>
                        <Typography variant="p" className={style.detail} component="p">{event.theme}</Typography>
                        <Typography variant="h4" className={style.title} component="h4">Event Subject</Typography>
                        <Typography variant="p" className={style.detail} component="p">
                            {event.subject}
                        </Typography>
                        <Typography variant="h4" className={style.title} component="h4">Project Manager</Typography>
                        <Box className={style.profileBox} >
                            <Box className={style.image} >
                                <img
                                    src={clubDefaultLogo}
                                    alt="Person Profile"
                                />
                            </Box>
                            <Box>
                                <Typography variant="h4" className={style.title} component="h4">Name: <span>{event.project_manager}</span></Typography>
                                <Typography variant="h4" className={style.title} component="h4">Position: <span>VP Event</span></Typography>
                            </Box>
                        </Box>
                        <Typography variant="h4" className={style.title} component="h4">{event.time}</Typography>
                        <Box className={style.datebox}>
                            <Typography variant="h4" className={style.title} component="h4">{`${monthNames[event.month]} ${event.date} ${event.year}`}</Typography>
                            {/* <Button
                                variant="outlined"
                                sx={{
                                    // width: '116px',
                                    // height: '34px',
                                    padding: '16px 12px',
                                    borderRadius: '8px',
                                    borderColor: '#00CDE1',
                                    bgcolor: '#00CDE1',
                                    ':hover': {
                                        borderColor: '#00CDE1',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        color: '#fff',
                                        textTransform: 'none', // Prevents text from being converted to uppercase  
                                    }}
                                >
                                    13 h - 16 h
                                </Typography>
                            </Button> */}
                        </Box>
                        <Box>
                            <Button
                                variant="outlined"
                                sx={{
                                    // width: '116px',
                                    // height: '34px',
                                    padding: '12px 25px',
                                    borderRadius: '8px',
                                    borderColor: '#00CDE1',
                                    bgcolor: '#00CDE1',
                                    color: '#fff',
                                    ':hover': {
                                        borderColor: '#00CDE1',
                                        color: '#00CDE1',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        textTransform: 'none', // Prevents text from being converted to uppercase  
                                    }}
                                >
                                    Save My Seat
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default EventModal;